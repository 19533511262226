<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a @click.prevent="menuToggle" :title="toggleTooltip" class="navbar-item is-desktop-icon-only is-hidden-touch">
        <b-icon :icon="menuToggleIcon"/>
      </a>
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
      <div class="navbar-item no-left-space has-control">
        <div class="control">
        </div>
      </div>
    </div>
    <div class="navbar-brand is-right">
		<!--
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="updatesToggle">
        <b-icon icon="bell" custom-size="default"/>
      </a>
		-->
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">
		<div class="navbar-item has-divider">
          <span>{{ userName }}</span>
        </div>
		<a class="navbar-item has-divider" href="https://morudake.com/manual/morudake_admin_guide.pdf" target="_blank">
          <b-icon pack="fas" icon="book" custom-size="default"/>
          <span>マニュアル</span>
        </a>
		<!--
        <nav-bar-menu class="has-divider has-user-avatar">
          <div class="is-user-name">
            <span>{{ auth.name }}</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="account" custom-size="default"/>
              <span>My Profile</span>
            </router-link>
            <a class="navbar-item">
              <b-icon icon="settings" custom-size="default"/>
              <span>Settings</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="email" custom-size="default"/>
              <span>Messages</span>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item">
              <b-icon icon="logout" custom-size="default"/>
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
        <nav-bar-menu class="has-divider">
          <b-icon icon="menu" custom-size="default"/>
          <span>Sample Menu</span>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="account" custom-size="default"/>
              <span>My Profile</span>
            </router-link>
            <a class="navbar-item">
              <b-icon icon="settings" custom-size="default"/>
              <span>Settings</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="email" custom-size="default"/>
              <span>Messages</span>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item">
              <b-icon icon="logout" custom-size="default"/>
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
        <a href="https://justboil.me/bulma-admin-template/one" class="navbar-item has-divider is-desktop-icon-only" title="About">
          <b-icon icon="help-circle-outline" custom-size="default"/>
          <span>About</span>
        </a>
        <a class="navbar-item has-divider is-desktop-icon-only" :class="{'is-active':isAsideRightVisible}" title="Updates" @click.prevent="updatesToggle">
          <b-icon icon="bell" custom-size="default" :class="{'has-update-mark':hasUpdates}" />
          <span>Updates</span>
        </a>
		-->
        <a class="navbar-item is-desktop-icon-only" title="Log out" @click="logout">
          <b-icon pack="fas" icon="sign-out-alt" custom-size="default"/>
          <span>ログアウト</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'NavBar',
  components: {
    //UserAvatar,
    //NavBarMenu
  },
  data () {
    return {
      isMenuNavBarActive: false
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon () {
      return this.isAsideExpanded ? 'backburger' : 'forwardburger'
    },
    toggleTooltip () {
      return this.isAsideExpanded ? 'Collapse' : 'Expand'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'isAsideRightVisible',
      'userName',
      'hasUpdates',
    ])
  },
  methods: {
    menuToggle () {
      this.$store.commit('asideStateToggle')
    },
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    updatesToggle () {
      this.$store.commit('asideRightToggle')
    },
    logout () {
		this.$emit('logout');
    }
  }
}
</script>
