import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/full-page/Login.vue'
import store from '../store/index.js'
import Auth from '../classes/Auth.js'

Vue.use(VueRouter)

const routes = [
	{
		meta: {
			title: 'オーダー',
			requireAuth: true,
		},
		path: '/',
		name: 'order',
		component: () => import(/* webpackChunkName: "order" */ '../views/Order.vue')
	},
	{
		meta: {
			title: 'オーダー詳細',
			requireAuth: true,
		},
		path: '/order-detail/:orderId',
		name: 'order-detail',
		component: () => import(/* webpackChunkName: "order" */ '../views/OrderDetail.vue')
	},
	{
		meta: {
			title: 'マイナス伝票',
			requireAuth: true,
		},
		path: '/credit-note',
		name: 'credit-note',
		component: () => import(/* webpackChunkName: "order" */ '../views/CreditNote.vue')
	},
	{
		meta: {
			title: 'マイナス伝票登録',
			requireAuth: true,
		},
		path: '/credit-note-new',
		name: 'credit-note-new',
		component: () => import(/* webpackChunkName: "order" */ '../views/CreditNoteEdit.vue')
	},
	{
		meta: {
			title: 'マイナス伝票編集',
			requireAuth: true,
		},
		path: '/credit-note-edit/:creditNoteId',
		name: 'credit-note-edit',
		component: () => import(/* webpackChunkName: "order" */ '../views/CreditNoteEdit.vue')
	},
	{
		meta: {
			title: 'マイナス伝票詳細',
			requireAuth: true,
		},
		path: '/credit-note-detail/:creditNoteId',
		name: 'credit-note-detail',
		component: () => import(/* webpackChunkName: "order" */ '../views/CreditNoteDetail.vue')
	},
	{
		meta: {
			title: 'アカウント',
			requireAuth: true,
		},
		path: '/account',
		name: 'account',
		component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
	},
	{
		meta: {
			title: 'アカウント登録',
			requireAuth: true,
		},
		path: '/account-new',
		name: 'account-new',
		component: () => import(/* webpackChunkName: "account" */ '../views/AccountEdit.vue')
	},
	{
		meta: {
			title: 'アカウント編集',
			requireAuth: true,
		},
		path: '/account-edit/:accountId',
		name: 'account-edit',
		component: () => import(/* webpackChunkName: "account" */ '../views/AccountEdit.vue')
	},
	{
		meta: {
			title: 'グループ',
			requireAuth: true,
		},
		path: '/group',
		name: 'group',
		component: () => import(/* webpackChunkName: "group" */ '../views/Group.vue')
	},
	{
		meta: {
			title: 'グループ登録',
			requireAuth: true,
		},
		path: '/group-new',
		name: 'group-new',
		component: () => import(/* webpackChunkName: "group" */ '../views/GroupEdit.vue')
	},
	{
		meta: {
			title: 'グループ編集',
			requireAuth: true,
		},
		path: '/group-edit/:groupId',
		name: 'group-edit',
		component: () => import(/* webpackChunkName: "group" */ '../views/GroupEdit.vue')
	},
	{
		meta: {
			title: '加盟店',
			requireAuth: true,
		},
		path: '/store',
		name: 'store',
		component: () => import(/* webpackChunkName: "store" */ '../views/Store.vue')
	},
	{
		meta: {
			title: '加盟店登録',
			requireAuth: true,
		},
		path: '/store-new',
		name: 'store-new',
		component: () => import(/* webpackChunkName: "store" */ '../views/StoreEdit.vue')
	},
	{
		meta: {
			title: '加盟店編集',
			requireAuth: true,
		},
		path: '/store-edit/:storeId',
		name: 'store-edit',
		component: () => import(/* webpackChunkName: "store" */ '../views/StoreEdit.vue')
	},
	{
		meta: {
			title: 'ブランド',
			requireAuth: true,
		},
		path: '/brand',
		name: 'brand',
		component: () => import(/* webpackChunkName: "brand" */ '../views/Brand.vue')
	},
	{
		meta: {
			title: 'ブランド登録',
			requireAuth: true,
		},
		path: '/brand-new',
		name: 'brand-new',
		component: () => import(/* webpackChunkName: "brand" */ '../views/BrandEdit.vue')
	},
	{
		meta: {
			title: 'ブランド編集',
			requireAuth: true,
		},
		path: '/brand-edit/:brandId',
		name: 'brand-edit',
		component: () => import(/* webpackChunkName: "brand" */ '../views/BrandEdit.vue')
	},
	{
		meta: {
			title: 'カテゴリー',
			requireAuth: true,
		},
		path: '/category',
		name: 'category',
		component: () => import(/* webpackChunkName: "category" */ '../views/Category.vue')
	},
	{
		meta: {
			title: 'カテゴリー登録',
			requireAuth: true,
		},
		path: '/category-new',
		name: 'category-new',
		component: () => import(/* webpackChunkName: "category" */ '../views/CategoryEdit.vue')
	},
	{
		meta: {
			title: 'カテゴリー編集',
			requireAuth: true,
		},
		path: '/category-edit/:categoryId',
		name: 'category-edit',
		component: () => import(/* webpackChunkName: "category" */ '../views/CategoryEdit.vue')
	},
	{
		meta: {
			title: 'メニュー',
			requireAuth: true,
		},
		path: '/menu',
		name: 'menu',
		component: () => import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
	},
	{
		meta: {
			title: 'メニュー登録',
			requireAuth: true,
		},
		path: '/menu-new',
		name: 'menu-new',
		component: () => import(/* webpackChunkName: "menu" */ '../views/MenuEdit.vue')
	},
	{
		meta: {
			title: 'メニュー編集',
			requireAuth: true,
		},
		path: '/menu-edit/:menuId',
		name: 'menu-edit',
		component: () => import(/* webpackChunkName: "menu" */ '../views/MenuEdit.vue')
	},
	{
		meta: {
			title: '売上',
			requireAuth: true,
		},
		path: '/sales',
		name: 'sales',
		component: () => import(/* webpackChunkName: "information" */ '../views/Sales.vue')
	},
	{
		meta: {
			title: '請求書',
			requireAuth: true,
		},
		path: '/bill',
		name: 'bill',
		component: () => import(/* webpackChunkName: "information" */ '../views/Bill.vue')
	},
	{
		meta: {
			title: 'お知らせ',
			requireAuth: true,
		},
		path: '/information',
		name: 'information',
		component: () => import(/* webpackChunkName: "information" */ '../views/Information.vue')
	},
	{
		meta: {
			title: 'お知らせ登録',
			requireAuth: true,
		},
		path: '/information-new',
		name: 'information-new',
		component: () => import(/* webpackChunkName: "information" */ '../views/InformationEdit.vue')
	},
	{
		meta: {
			title: 'お知らせ編集',
			requireAuth: true,
		},
		path: '/information-edit/:informationId',
		name: 'information-edit',
		component: () => import(/* webpackChunkName: "information" */ '../views/InformationEdit.vue')
	},
	//{
	//	meta: {
	//		title: 'ダッシュボード',
	//		requireAuth: true,
	//	},
	//	path: '/',
	//	name: 'dashboard',
	//	// route level code-splitting
	//	// this generates a separate chunk (about.[hash].js) for this route
	//	// which is lazy-loaded when the route is visited.
	//	component: () => import(/* webpackChunkName: "tables" */ '../views/Dashboard.vue')
	//},
	//{
	//	meta: {
	//		title: 'Tables'
	//	},
	//	path: '/tables',
	//	name: 'tables',
	//	// route level code-splitting
	//	// this generates a separate chunk (about.[hash].js) for this route
	//	// which is lazy-loaded when the route is visited.
	//	component: () => import(/* webpackChunkName: "tables" */ '../views/Tables.vue')
	//},
	//{
	//	meta: {
	//		title: 'Forms'
	//	},
	//	path: '/forms',
	//	name: 'forms',
	//	component: () => import(/* webpackChunkName: "forms" */ '../views/Forms.vue')
	//},
	//{
	//	meta: {
	//		title: 'Profile'
	//	},
	//	path: '/profile',
	//	name: 'profile',
	//	component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
	//},
	//{
	//	meta: {
	//		title: 'New Client'
	//	},
	//	path: '/client/new',
	//	name: 'client.new',
	//	component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue')
	//},
	//{
	//	meta: {
	//		title: 'Edit Client'
	//	},
	//	path: '/client/:id',
	//	name: 'client.edit',
	//	component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
	//	props: true
	//},
	{
		path: '/full-page',
		component: () => import(/* webpackChunkName: "full-page" */ '../views/FullPage.vue'),
		children: [
			{
				meta: {
					title: 'ログイン',
					requireAuth: false,
				},
				path: '/login',
				name: 'login',
				component: Login
			},
			//{
			//	meta: {
			//		title: 'Password Recovery'
			//	},
			//	path: '/password-recovery',
			//	name: 'password-recovery',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/PasswordRecovery.vue')
			//},
			//{
			//	meta: {
			//		title: 'Error v.1'
			//	},
			//	path: '/error-in-card',
			//	name: 'error-in-card',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/Error.vue')
			//},
			//{
			//	meta: {
			//		title: 'Error v.2'
			//	},
			//	path: '/error-simple',
			//	name: 'error-simple',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/Error.vue'),
			//	props: { isInCard: false }
			//},
			//{
			//	meta: {
			//		title: 'Lock screen'
			//	},
			//	path: '/lock-screen',
			//	name: 'lock-screen',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/LockScreen.vue')
			//}
		]
	}
]

const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requireAuth)) {
		// 認証トークン存在チェック
		if (!store.state.token) {
			next({ path: '/login' });
		}

		// アクセスコントロール
		if (store.state.userPermissionId !== 1) {
			if (!(to.name === 'order' || to.name === 'order-detail')) {
				let auth = new Auth();
				auth.logout();
				next({ path: '/login' });
			}
		}
	}

	next();
})

export default router
