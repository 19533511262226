/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

import Utils from '@/classes/Utils'
const utils = new Utils(process.env.VUE_APP_IS_DEBUG)
Vue.prototype.$utils = utils

import Api from '@/classes/Api'
const api = new Api()
Vue.prototype.$api = api

import Auth from '@/classes/Auth'
const auth = new Auth()
Vue.prototype.$auth = auth

import Consts from '@/classes/Consts'
const consts = new Consts()
Vue.prototype.$consts = consts

const defaultDocumentTitle = consts.APP_NAME;

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
	store.commit('asideStateToggle', store.state.isAsideExpanded)
	store.commit('asideMobileStateToggle', false)
	store.commit('overlayToggle', false)
	store.commit('asideActiveForcedKeyToggle', null)

	if (to.meta && to.meta.title) {
		document.title = `${to.meta.title} | ${defaultDocumentTitle}`
	} else {
		document.title = defaultDocumentTitle
	}
})

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
	router,
	store,
	render: h => h(App),
	mounted () {
		document.documentElement.classList.remove('has-spinner-active')
	}
}).$mount('#app')
