class Consts {
	constructor() {
		this.APP_NAME = 'モルダケ本部管理画面';
		this.MSG_CONFIRM_REGISTER = 'データを登録します。よろしいですか？';
		this.MSG_CONFIRM_DELETE = '選択したデータを削除します。よろしいですか？';
		this.TABLE_PER_PAGE = 10;
		this.COMPANY_NAME = 'モルダケ';
	}
}

export default Consts
