<template>
	<div class="login">
		<img class="logo" src="/img/logo.png">
		<card-component :title="$consts.APP_NAME + ' ログイン'" icon="lock" :has-card-header-background="true">
			<b-field label="メールアドレス">
				<b-input v-model="email" type="text" autofocus/>
			</b-field>

			<b-field label="パスワード">
				<b-input type="password" v-model="password" @keydown.enter.native="onLoginClick"/>
			</b-field>

			<hr>

			<b-field grouped>
				<div class="control">
					<div class="login-error mb-10" v-show="isLoginError">ログイン情報が間違っています</div>
					<button type="button" class="button is-primary" :class="{'is-loading':isLoading}" @click="onLoginClick">
						ログイン
					</button>
				</div>
			</b-field>
		</card-component>
	</div>
</template>

<style lang="scss" scoped>
.login-error {
	color: red;
}

.logo {
	width: 140px;
	display: block;
	margin: -50px auto 80px;
}
</style>

<script>
import CardComponent from '@/components/CardComponent'

export default {
	name: 'Login',
	components: { CardComponent },
	data () {
		return {
			isLoading: false,
			isLoginError: false,
			email: '',
			password: '',
		}
	},
	methods: {
		onLoginClick: function() {
			this.isLoginError = false;
			this.isLoading = true;

			this.$auth.login(this.email, this.password)
				.then(() => {
					let nextRoute = this.$auth.isAdmin ? 'account' : 'order';
					this.$router.push({ name: nextRoute })
				})
				.catch((error) => {
					if (parseInt(error.message) === 401) {
						this.isLoginError = true;
					} else {
						this.$utils.toastError('サーバとの通信に失敗しました');
					}
				})
				.finally(() => {
					this.isLoading = false;
				}
			);
		}
	}
}
</script>
